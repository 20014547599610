body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

@font-face{
  font-family: wood;
  src: url("./assets/fonts/RosewoodFreeRegular-LVAvW.ttf")
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide the scrollbar for all browsers */
.scrollable-container {
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollable-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}
.slick-slide {
  width: 200px !important;
}
.slick-list {
  height: 250px;
}
.slick-track {
  top: 10px !important;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
